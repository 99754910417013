<template>  
    <header id="header">
      <h1 id="logo">        
        <router-link to="/">Keeper</router-link>
      </h1>
      <nav id="nav">
        <ul><!--
          <li><router-link to="/">Home</router-link></li>
          <li>
            <a href="#">Layouts</a>
            <ul>
              <li>                
                <router-link to="/LeftSidebar">Left Sidebar</router-link>                
                </li>
              <li><a href="/RightSidebar">Right Sidebar</a></li>
              <li><a href="/NoSideBar">No Sidebar</a></li>
              <li>
                <a href="#">Submenu</a>
                <ul>
                  <li><a href="#">Option 1</a></li>
                  <li><a href="#">Option 2</a></li>
                  <li><a href="#">Option 3</a></li>
                  <li><a href="#">Option 4</a></li>
                </ul>
              </li>
            </ul>
          </li>-->
          <li>
             <router-link to="/contacts" target="_blank">Diventa rivenditore</router-link>              
          </li>
          <li>
            <a href="https://shop.keeperbt.com">Shop</a>
          </li>
          <li>
            <a href="https://keeperbt.atlassian.net/servicedesk/customer/portals">Customer Service</a>
            <!-- <router-link to="/help" target="_blank">Help</router-link>                            -->
            </li>
          <!--<li><a href="#" class="button primary">Sign Up</a></li>-->
        </ul>
      </nav>
    </header>
</template>

<script>

export default {
  name: 'Header',    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

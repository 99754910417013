<template>
  <!-- Footer -->
  <footer id="footer">
    <ul class="icons">
      <!--<li>
        <a href="#" class="icon brands alt fa-twitter"
          ><span class="label">Twitter</span></a
        >
      </li>-->
      <li>
        <a href="https://fb.me/keeper.bt" class="icon brands alt fa-facebook-f"
          ><span class="label">Facebook</span></a
        >
      </li>
      <!--<li>
        <a href="#" class="icon brands alt fa-linkedin-in"
          ><span class="label">LinkedIn</span></a
        >
      </li>-->
      <li>
        <a href="https://www.instagram.com/keeper.bt/" class="icon brands alt fa-instagram"
          ><span class="label">Instagram</span></a
        >
      </li>
      <!--<li>
        <a href="#" class="icon brands alt fa-github"
          ><span class="label">GitHub</span></a
        >
      </li>-->
      <li>
        <a href="mailto:info@keeperbt.com" class="icon solid alt fa-envelope"
          ><span class="label">Email</span></a
        >
      </li>
    </ul>
    <ul class="copyright">
      <li>&copy; Keeper. All rights reserved.</li>
      <li><a href="/privacy-policy">Privacy Policy</a></li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <div id="page-wrapper">
    <Header />

    <!-- Banner -->
    <section id="banner">
      <div class="content">
        <header>
          <img src="../images/logo-keeper.png" style="width: 300px" />
          <p class="bold">Il guardiano delle tue chiavi</p>
        </header>
        <span class="image"><img src="../images/logo.png" alt="" /></span>
      </div>
      <a href="#one" class="goto-next scrolly">Next</a>
    </section>

    <!-- One -->
    <section id="one" class="spotlight style1 bottom">
      <span class="image fit main"
        ><img src="../images/pic-books.png" alt=""
      /></span>
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-4 col-12-medium">
              <p>
                Solitamente si perdono gli oggetti nei momenti in cui si è di
                fretta e la cosa peggiore è perdere del tempo prezioso per
                cercarli.
              </p>
            </div>
            <div class="col-4 col-12-medium">
              <header>
                <h2></h2>
                <p>
                  Quante volte, prima di uscire di casa hai perso tempo a
                  cercare le chiavi?
                </p>
              </header>
            </div>
            <div class="col-4 col-12-medium">
              <p>
                Keeper è il portachiavi intelligente che ti aiuta a trovare gli
                oggetti in qualsiasi momento.
              </p>
              <ul class="actions">
                <li>
                  <a href="https://shop.keeperbt.com" class="button"
                    >Acquista ora</a
                  >
                </li>
              </ul>
              <!--<p>
                Morbi enim nascetur et placerat lorem sed iaculis neque ante
                adipiscing adipiscing metus massa. Blandit orci porttitor
                semper. Arcu phasellus tortor enim mi mi nisi praesent
                adipiscing. Integer mi sed nascetur cep aliquet augue varius
                tempus. Feugiat lorem ipsum dolor nullam.
              </p>-->
            </div>
          </div>
        </div>
      </div>
      <a href="#two" class="goto-next scrolly">Next</a>
    </section>

    <!-- Two -->
    <section id="two" class="spotlight style2 right">
      <span class="image fit main"
        ><img src="../images/pic-keys.png" alt=""
      /></span>
      <div class="content">
        <header>
          <h2>Come funziona?</h2>
          <!--<p>Nunc commodo accumsan eget id nisi eu col volutpat magna</p>-->
        </header>
        <p>
          Keeper lo puoi agganciare ai tuoi oggetti e dialogherà costantemente
          con il tuo smartphone attraverso il protocollo bluetooth, è progettato
          per lavorare a breve raggio (max. 10 mt.) per tenere sempre tutto
          sotto controllo.
        </p>
        <p>
          Se ti allontani troppo Keeper ti invierà una notifica avvisandoti che
          si è scollegato e registrerà la sua ultima posizione rilevata, in caso
          contrario potrai farlo squillare dall’app per identificare la sua
          posizione.
        </p>
        <!--<ul class="actions">
          <li><a href="#" class="button">Learn More</a></li>
        </ul>-->
      </div>
      <a href="#three" class="goto-next scrolly">Next</a>
    </section>

    <!-- Three -->
    <section id="three" class="spotlight style3 left">
      <span class="image fit main bottom"
        ><img src="../images/pic-bus.png" alt=""
      /></span>
      <div class="content">
        <header>
          <h2>Stop ai ritardi!</h2>
        </header>
        <p>
          Dimenticati i ritardi agli appuntamenti e lo stress generato di
          conseguenza, noi vogliamo agevolare la tua giornata partendo dalle
          cose semplici come ricordarti dove hai lasciato le chiavi o il
          telefono.
        </p>
        <p>
          Scegli Keeper il portachiavi intelligente e cool che ti aiuta a
          trovare gli oggetti in qualsiasi momento.
        </p>
        <ul class="actions">
          <li>
            <a href="https://shop.keeperbt.com" class="button">Acquista ora</a>
          </li>
        </ul>
      </div>
      <a href="#four" class="goto-next scrolly">Next</a>
    </section>

    <!-- Five -->
    <!--<section id="five" class="wrapper style2 special fade">
      <div class="container">
        <header>
          <h2>Magna faucibus lorem diam</h2>
          <p>Ante metus praesent faucibus ante integer id accumsan eleifend</p>
        </header>
        <form method="post" action="#" class="cta">
          <div class="row gtr-uniform gtr-50">
            <div class="col-8 col-12-xsmall">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Your Email Address"
              />
            </div>
            <div class="col-4 col-12-xsmall">
              <input type="submit" value="Get Started" class="fit primary" />
            </div>
          </div>
        </form>
      </div>
    </section>
-->
    

    <!-- Four -->
    <section id="four" class="wrapper style1 special fade-up">
      <div class="container">
        <header class="major">
          <h2>Cosa puoi fare con Keeper?</h2>
          <p>Ecco l'elenco delle funzioni più comuni</p>
        </header>
        <div class="box alt">
          <div class="row gtr-uniform">
            <section class="col-4 col-6-medium col-12-xsmall">
              <span class="icon solid alt major fa-volume-up"></span>
              <h3>“Squillare” Keeper da app</h3>
              <p>Fai squillare Keeper dal tuo smartphone.</p>
            </section>
            <section class="col-4 col-6-medium col-12-xsmall">
              <span class="icon solid alt major fa-phone-volume"></span>
              <h3>“Squillare” telefono da keeper</h3>
              <p>
                Premi due volte il logo di Keeper per far squillare il tuo
                telefono (anche silenzioso).
              </p>
            </section>
            <section class="col-4 col-6-medium col-12-xsmall">
              <span class="icon solid alt major fa-search-location"></span>
              <h3>Localizzatore</h3>
              <p>Trova i tuoi device su mappa.</p>
            </section>
            <!--<section class="col-4 col-6-medium col-12-xsmall">
              <span class="icon solid alt major fa-paper-plane"></span>
              <h3>Non semper interdum</h3>
              <p>
                Feugiat accumsan lorem eu ac lorem amet accumsan donec. Blandit
                orci porttitor.
              </p>
            </section>
            <section class="col-4 col-6-medium col-12-xsmall">
              <span class="icon solid alt major fa-file"></span>
              <h3>Odio laoreet accumsan</h3>
              <p>
                Feugiat accumsan lorem eu ac lorem amet accumsan donec. Blandit
                orci porttitor.
              </p>
            </section>
            <section class="col-4 col-6-medium col-12-xsmall">
              <span class="icon solid alt major fa-lock"></span>
              <h3>Massa arcu accumsan</h3>
              <p>
                Feugiat accumsan lorem eu ac lorem amet accumsan donec. Blandit
                orci porttitor.
              </p>
            </section>-->
          </div>
        </div>
        <footer class="major">
          <ul class="actions special">
            <li>
              <a href="https://shop.keeperbt.com" class="button"
                >Acquista ora</a
              >
            </li>
          </ul>
        </footer>
      </div>
    </section>


    <section id="five" class="wrapper style2 special fade">
      <div class="container">
        <header>
          <h2>Hai una azienda?</h2>
          <p>Diventa rivenditore di Keeper e scopri i vantaggi</p>
        </header>
        <router-link to="/contacts" target="_blank" class="button primary">Clicca qui
        </router-link>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  mounted: function () {
    window.animateAll();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

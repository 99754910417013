<template>
  <div id="page-wrapper">
    <!-- Header -->
    <Header />

    <!-- Main -->
    <div id="main" class="wrapper style1">
      <div class="container">
        <header class="major">
          <h2>Privacy Policy</h2>
          <small>
            Effective date: 2020 December, 31
          </small><br />
        </header>

        <!-- Content -->
        <section id="content">
          <p>
            This policy describes what information we collect from you and how we use it to accomplish that mission.
We take your privacy as seriously as you do, and because of that we take great care to collect only the information necessary to provide you with the best possible Keeper experience.
As you review our policy, keep in mind that it applies to (“Keeper”, “We”, “Us”) website, mobile app, help center, and all other associated services that we provide (“Services”).
          </p>

          <h3>Information Keeper collects</h3>

          <p>
           The information Keeper collects depends on what Keeper services you use and how you use them. Please see below for more details.              
							<ol type="1">
								<li><div>Placing an order</div>
                <ol class="alphabet">
                  <li>Contact Information
                    <div>
                    When you place an order on our website, and otherwise when needed to respond to a specific request, you’ll need to provide an email address, a first and last name and/or a shipping address. In some cases, we may also require your phone number
                    </div>
                  </li>
                  <li>Payment Information
<div>
In order to complete your order on our website, you’ll need to provide	
payment information, such as your credit card or PayPal account. This
information is needed in order to process your order and is not stored on servers.
</div>
                  </li>
                </ol>                
								<li>Information from Your Use of the Services</li>
								<ol class="alphabet"><li>Keeper is designed to tell you where your items are. In order to do that, it’s necessary to collect data about your location. We use the term “Location Information” to refer to the combined location data of your phone or device, and your Keepers.<br />
While the app is running on your device, it periodically transmits your Location Information. This allows us to show you, on your map, the last place your Keeper was seen by your device (“Last Place Seen”). It is one of the primary ways Keeper helps you find your lost items.
Keeper normally only displays the latest Location Information update for each of your Keepers in your app, to provide the Last Place Seen.
</li></ol>							
							</ol>
           </p>


<h3>Cookies and Other Web Technologies</h3>
<ol>
  <li>Cookies
    <div>
Cookies are small text files that are placed on your computer’s hard drive or onto your mobile device’s memory -- if your browser is set to accept cookies. Like many other online services, cookies and other tools like them help us collect and understand how you use our services.<br />
Although most internet browsers automatically accept cookies, you can change your settings or use third-party tools to prompt you before accepting cookies from the websites you choose to visit, or to refuse cookies altogether. You can even delete them through your browser. Most browsers also have settings to prevent them from accepting new cookies, to notify you when you receive a new cookie, or to delete or disable cookies altogether. Some parts of our Services may not work for you if you disable cookies.
    </div>
  </li>

  <li>How We Use Cookies
    <div>
We use cookie technology in various ways to help make it easier for you to use our Services. For example, we use cookies to remember you when you return to our website, identify you when you sign-in, authenticate your access, keep track of your in-app specified preferences or choices, tailor content to you or geographic region, display a personalized browsing history, and/or to provide specific technical support.
We may also use cookies to gauge site usage, conduct research and diagnostics to improve the content we provide you, our products and services, and to help us analyze general web traffic.
<br />
The cookies we use expire after they have fulfilled their purpose. Some cookies expire when you close your browser (i.e., session cookies) or after a set period of time (i.e., persistent cookies). The expiration time of cookies that are placed by third parties (explained below) is determined by the third-party, not us.
    </div>
  </li>


  <li>Web Beacons
    <div>
      Web beacons (also known as web bugs, pixel tags or clear GIFs) are tiny graphics with a unique identifier that may be included on our Services for several purposes, including to deliver or communicate with cookies, to track and measure the performance of our Services, to monitor how many visitors view our Services, and to monitor the effectiveness of our advertising. Unlike cookies, which are stored on the user’s hard drive, web beacons are typically embedded invisibly on web pages (or in an e-mail).
    </div>
  </li>

  <li>Do Not Track</li>
  <div>While our website and the Keeper app at this time do not recognize automated browser signals regarding tracking mechanisms, such as "do not track" instructions, you can generally express your privacy preferences regarding the use of most cookies and similar technologies through your web browser, as indicated above.
  </div>
</ol>


<h3>Collection of Your Information by Third-Party Services</h3>
<p>Sometimes Keeper uses third-party service providers. These third-party service providers may place cookies on your computer's hard drive or your mobile device's memory or use web beacons to collect or receive information from the Services and elsewhere on the internet and use that information to provide analytics and to target ads on Keeper's behalf</p>
<ol>
<li>
Web Analysis Services
<div>
We may use third-party web analysis services which use cookies or other web technologies to collect usage information, matched to an IP address, to help us analyze how our users use our website and some portions of the Keeper app. We collect this information in order to improve the overall experience of our Services for our users.
We utilize Google Analytics, a service provided by Google, Inc. to gather information about how users and visitors engage with our Site and Services. For more information about Google Analytics, please visit <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>.
You can choose not to be included in Google Analytics at
<a href="https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en">https://chrome.google.com/webstore/detail/google-analytics-opt-out/fllaojicojecljbmefodhfapmkghcbnh?hl=en</a>. 
These outside services may also collect information about your use of other websites over time. Since we do not link any information that you provide to us within the app to the information we store within the third-party analytics software, this additional information is stored in a way that does not directly identify you.
</div>
</li>

<li>Social Plugins and Social Widgets
<div>We may use social plugins, widgets and other features (each, a “Social Feature”) that are made available by and/or access third-party Social Networks. These Social Features allow these Social Networks to place cookies on your browser and to collect certain information, which may be associated with your name and personal Social Network account.<br />
These Social Features are operated solely by the respective Social Networks, and their service providers, and we recommend that you carefully read their privacy policies before you decide to use them. We have no control over or access to the information collected, stored or used by such Social Networks, and the information practices of such Social Networks are not covered by this Privacy Policy.<br />
If you do not wish to associate any information collected via the plugins, widgets and/or other features with your personal Social Network account information, you should refrain from using these Social Features and logout from your Social Network account before visiting our Services.
</div>
</li>


<li>
  Third-Party Payment Processing
<div>When you purchase a Keeper, we process your payments through a third-party application, including PayPal (together with any similar applications, “App Stores”) and Social Networks such as Facebook. The third-party application may collect certain financial information from you to process a payment on behalf of Keeper, including your name, email address, address and other billing information.</div>
</li>

</ol>


<h3>You May Access, Modify, and Rectify Your Information</h3>
<p>You can access and modify your contact information, such as your name, email address or phone number, within the Services, or by contacting us at <a href="mailto:privacy@keeperbt.com">privacy@keeperbt.com</a>. Where otherwise permitted by applicable law, you may send an e-mail or use any of the methods set out in this Privacy Policy to request to know the categories of information we collect or disclose about you, the categories of sources of such information, the business or commercial purpose for collecting your information and the categories of third parties with whom we share personal information. All of this information is also set forth in this Privacy Policy.</p>
<p>You may also request that we provide access to and/or a copy of certain information we hold about you, seek rectification, or request erasure of certain personal information held about you by Keeper.</p>
<p>Please include your full name, email address associated with your Account, and a detailed description of your data request. Such requests will be processed in line with local laws. Under Privacy Shield or other applicable laws, you may also inquire as to whether the Keeper is processing personal information about you, request access to personal information, and ask that we correct, amend or delete it where it is inaccurate or has been Processed in violation of the Privacy Shield Principles.</p>
<p>Although Keeper makes good faith efforts to provide individuals with access to their personal information, there may be circumstances in which Keeper is unable to provide access and is not legally obligated to do so, including (depending on applicable law) but not limited to: where the information contains legal privilege, would compromise others’ privacy or other legitimate rights, where the burden or expense of providing access would be disproportionate to the risks to the individual’s privacy in the case in question or where it is commercially proprietary. To protect your privacy, Keeper will take commercially reasonable steps to verify your identity before granting access to or making any changes to your personal information.</p>

<h3>Your Account May Be Deleted Upon Your Request</h3>

<p>
  If you want us to delete your account, please contact us at <a href="mailto:privacy@keeperbt.com">privacy@keeperbt.com</a> with your request. We’ll take steps to delete your requested personal information as soon as we can, but some information may remain in archived/backup copies for our records or as otherwise required or permitted by law.
</p>

<p>
  Please note, however, that if you delete your account or other personal information , the Location Information associated with any active Keeper(s) may still be collected anonymously from other Keeper users who are running the app within Bluetooth range of your device. However, any new Location Information that is collected will no longer be associated with your account. You may dispose of your Keepers at a local e-waste facility or contact customer care at <a href="mailto:privacy@keeperbt.com">privacy@keeperbt.com</a> for more information.
</p>
        </section>
      </div>
    </div>
    
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "PrivacyPolicy",
  components: {
    Header,
    Footer,
  },
  mounted: function () {
    window.animateAll();
  },
};
</script>
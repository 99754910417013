import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Elements from '../views/Elements.vue'
import LeftSidebar from '../views/LeftSidebar.vue'
import RightSidebar from '../views/RightSidebar.vue'
import NoSideBar from '../views/NoSideBar.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import More from '../views/More.vue'
import Contacts from '../views/Contacts.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Elements',
    name: 'Elements',
    component: Elements
  },
  {
    path: '/LeftSidebar',
    name: 'LeftSidebar',
    component: LeftSidebar
  },
  {
    path: '/RightSidebar',
    name: 'RightSidebar',
    component: RightSidebar
  },
  {
    path: '/NoSideBar',
    name: 'NoSideBar',
    component: NoSideBar
  }, {
    path: '/privacy-policy',
    name: 'Privacy-policy',
    component: PrivacyPolicy
  }, {
    path: '/more',
    name: 'More',
    component: More
  }, 
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

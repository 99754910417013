<template>
  <div id="hubspotForm"></div>
</template>

<script>
export default {
  name: "HubspotForm",
  mounted: function () {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "19885372",
      formId: "6853957b-e17d-4795-8c36-7325ba776e32",
      target: "#hubspotForm",
    });

    let $ = window.$;

    setTimeout(function () {
      let $contents = $(".hs-form-iframe").contents();
      $contents.find(".hbspt-form form fieldset").css({ "max-width": "none" });
      $contents
        .find("input.hs-button.primary.large")
        .css({ "background-color": "#8FDC4B", "border-color": "#8FDC4B" });
      $contents.find(".sproket").css({ display: "none" });
      $contents.find(".hs-input").css({ "max-width": "none" });
    }, 750);
  },
};
</script>

<style>
</style>
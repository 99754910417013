<template>
  <div id="page-wrapper">
    <Header />

    <!-- Main -->
    <div id="main" class="wrapper style1">
      <div class="container">
        <header class="major">
          <h2>Richiesta contatto</h2>          
        </header>
        <HubspotForm />
      </div>
    </div>    
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import HubspotForm from "../components/HubspotForm";

export default {
  name: "Contacts",
  components: {
    Header,
    HubspotForm
  },
  mounted: function () {
    window.animateAll();    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
